<template>
  <div class="AccountLayout">
    <header class="header">
      <div class="top">Your Account</div>
      <account-navigation />
    </header>

    <router-view />
  </div>
</template>

<script>
import AccountNavigation from '@/components/Account/AccountNavigation.vue'

export default {
  components: {
    AccountNavigation
  }
}
</script>

<style lang="scss" scoped>
  .AccountLayout {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      margin-bottom: 10px;

      font-size: 24px;
      margin-bottom: 20px;
      font-weight: 600;
      letter-spacing: -0.017em;
      height: 40px;
    }

    .header {
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
</style>
